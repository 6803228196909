import React, { useState } from 'react';
import styles from './eventDetails.module.css';
import SearchHeader from '../../componentes/SearchHeader/searchHeader';
import { useLocation, useNavigate } from 'react-router';
import EventInfo from './eventInfo';
import AttendeeList from './attendeeList';
import Footer from './footer';
import SideBar from '../../componentes/SideBar/sideBar';
import { logout } from '../../../helper/auth';

const EventDetails = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const event = location.state.event
    const [search, setSearch] = useState()
    const handleLogout = () => {
        logout();
        navigate("/");
      };
    

    return (
        <div className={styles.eventDetailsContainer}>
            <SideBar handleLogout={handleLogout} />
            <main className={styles.mainContent}>
                <SearchHeader handleLogout={handleLogout}/>
                <div className={styles.eventContent}>
                    <EventInfo event={event} search={search} setSearch={setSearch}/>
                    <AttendeeList event={event} search={search}/>
                    <Footer />
                </div>
            </main>
        </div>
    );
};

export default EventDetails;