import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { IamTicketService } from "../../integrations/iam-ticket-service";

const EmailConfirmationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const [codeOTP, setCodeOTP] = useState();
  const [errors, setErrors] = useState({codeOTP: ""})

  const iamTicketService = new IamTicketService()

  const handleVerify = async () => {
    if(!email || !codeOTP) return setErrors({codeOTP: "*Por favor preencha o código"});
    if(codeOTP?.length !== 6) return setErrors({codeOTP: "*O código deve ter 6 dígitos"});
    const result = await iamTicketService.ValidateUser({ email, code: codeOTP})
    alert(result.message);
    if(result.success){
      navigate("/", { state: { email}});
    }
  };

  const handleResendCode = async () => {
    const result = await iamTicketService.ResendOTPCode({ email })
    alert(result.message);
  }
  
  return (
    <div className="body">
      <section className="camada">
        <a href="/">
          <img
            src="https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/Camada_1-Iv1yATRTkMVhhHn90EQ9vAHMNsPW4N.png"
            alt="Iamticket"
          />
        </a>
      </section>

      <section className="group">
        <div className="group__rectangle-2">
          <div className="group__emailmark">
            <img
              src="https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/emailmark-bnzCCNi6lEGyJDLShZCnDU3WPkksoY.png"
              alt="emailMark"
            />
          </div>
          
          <h1 class="group__title__emailconfirmation">Falta pouco para finalizarmos seu cadastro</h1>

          <div class="group__information">
            <p class="group__text__emailconfirmation">Enviamos um código de validação de seu cadastro para:</p><br/>
            <p class="group__text__emailconfirmation">{email}</p>
            <p class="group__text__emailconfirmation">Por favor, <strong className="highlight__paragraph">digite o código de validação</strong> para completar seu cadastro</p>
          </div>

          <input className="group__input" type="text" placeholder="Digite aqui" value={codeOTP} onChange={(e) => setCodeOTP(e.target.value)} />
          <p className={"group_errorMessages"}>{errors?.codeOTP}</p>

          <div className="group__link">
            <button class="group__btn__dark" onClick={handleVerify}>
              VALIDAR
            </button>

            <button class="group__btn__dark" onClick={handleResendCode}>
              REENVIAR CÓDIGO
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmailConfirmationPage;
