import React from "react";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "./../helper/auth";
import LoginPage from "../components/pages/loggin";
import SignupPage from "../components/pages/signup";
import AdminPage from "../components/pages/admin";
import ItemDetailsPage from "../components/pages/item-details";
import EventsPage from "../components/pages/event/eventPage";
import EmailConfirmationPage from "../components/pages/email-confirmation";
import EventRegistration from "../components/pages/eventRegistration/eventRegistration";
import EventDetails from "../components/pages/eventDetails/eventDetails";

const PrivateRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/" />;
}

const MyRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/email-confirmation" element={<EmailConfirmationPage />} />
      <Route exact path='/admin' element={<PrivateRoute />}>
        <Route path="/admin" element={<AdminPage />} />
      </Route>
      <Route exact path='/events' element={<PrivateRoute />}>
        <Route path="/events" element={<EventsPage />} />
      </Route>
      <Route exact path='/new-event' element={<PrivateRoute />}>
        <Route path="/new-event" element={<EventRegistration />} />
      </Route>
      <Route exact path='/event-details' element={<PrivateRoute />}>
        <Route path="/event-details" element={<EventDetails />} />
      </Route>
      <Route exact path='/item/:itemId' element={<PrivateRoute />}>
        <Route path="/item/:itemId" element={<ItemDetailsPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default MyRoutes;