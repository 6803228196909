import axios from "axios";
import { config } from "../configs/env";
export class IamTicketService {
    constructor(token = null) {
        this.url = `${config.integrations.iamTicketService.url}`;
        this.authorization =
            config.integrations.iamTicketService.authenticationToken;
        this.token = token;
    }

    async LogginUser(email, password) {
        try {
            const userNew = {
                email,
                password,
            };

            const { data } = await axios.post(`${this.url}/login`, userNew, {
                headers: { Authorization: this.authorization },
            });
            const { isAuth, token } = data;

            return { isAuth, token };
        } catch (error) {
            console.log("Error while trying to log user", error);
            return { isAuth: false, token: null };
        }
    }

    async AddUser(user, password) {
        try {
            const userNew = [
                {
                    user,
                    password,
                },
            ];
            await axios.post(`${this.url}/adduser`, userNew, {
                headers: { Authorization: this.authorization },
            });

            return true;
        } catch (error) {
            console.log("Error while trying to add user", error);
            return false;
        }
    }

    async Signup(user) {
        try {
            const response = await axios.post(`${this.url}/signup`, user, {
                headers: { Authorization: this.authorization },
            });

            return { message: response.data?.message, success: true };
        } catch (error) {
            console.log("Error while trying to add user", error);
            return { message: error.response?.data?.message ?? error.message, success: false };
        }
    }

    async ValidateUser(data) {
        try {
            const response = await axios.post(`${this.url}/validate-email`, data, {
                headers: { Authorization: this.authorization },
            });

            return { message: response.data?.message, success: true };
        } catch (error) {
            console.log("Error while trying to validate user", error);
            return { message: error.response?.data?.message ?? error.message, success: false };
        }
    }

    async ResendOTPCode(data) {
        try {
            const response = await axios.post(`${this.url}/resend-code`, data, {
                headers: { Authorization: this.authorization },
            });

            return { message: response.data?.message, success: true };
        } catch (error) {
            console.log("Error while trying to resend otp code user", error);
            return { message: error.response?.data?.message ?? error.message, success: false };
        }
    }

    async CreateEvent(data) {
        try {
            const response = await axios.post(`${this.url}/events`, data, {
                headers: { Authorization: this.authorization, token: this.token },
            });

            return { eventId: response.data?.event?._id, success: true };
        } catch (error) {
            console.log("Error while trying to create event", error);
            return { eventId: null, success: false };
        }
    }

    async UpdateEvent(eventId, data) {
        try {
            const response = await axios.patch(`${this.url}/event/${eventId}`, data, {
                headers: { Authorization: this.authorization, token: this.token },
            });

            return { eventId: response.data?.event?._id, success: true };
        } catch (error) {
            console.log("Error while trying to create event", error);
            return { eventId: null, success: false };
        }
    }

    async getAllEvents(status, search, page) {
        try {
            const { data } = await axios.get(
                `${this.url}/events?status=${status}&search=${search}&page=${page}`,
                {
                    headers: {
                        Authorization: this.authorization,
                        token: this.token,
                    },
                }
            );

            return data;
        } catch (error) {
            console.log("Error while trying get events", error);
            return null;
        }
    }

    async getAllTicketsByEvent(eventId, page, search) {
        try {
            const { data } = await axios.get(
                `${this.url}/tickets/${eventId}?page=${page}&search=${search}`,
                {
                    headers: {
                        Authorization: this.authorization,
                        token: this.token,
                    },
                }
            );

            return data;
        } catch (error) {
            console.log("Error while trying get events", error);
            return null;
        }
    }

    async getEventById(eventId) {
        try {
            const { data } = await axios.get(`${this.url}/event/${eventId}`, {
                headers: {
                    Authorization: this.authorization,
                    token: this.token,
                },
            });

            return data?.event;
        } catch (error) {
            console.log("Error while trying get event by id", error);
            return {};
        }
    }
}
