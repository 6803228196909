import React from 'react';
import InputField from './inputField';
import styles from './addressFields.module.css';

function AddressFields({ errors, register }) {
  return (
    <div className={styles.addressFieldset}>
      <InputField label="Endereço*" name={"street"} placeholder="Rua" register={register} registerField={"street"} errors={errors}/>
      <div className={styles.addressDetails}>
        <InputField label="Número*" name={"number"} placeholder="Número" register={register} registerField={"number"} errors={errors}/>
        <InputField label="Complemento" name={"complement"} placeholder="Complemento" register={register} registerField={"complement"} errors={errors}/>
        <InputField label="CEP*" name={"zip"} placeholder="CEP" register={register} registerField={"zip"} errors={errors}/>
      </div>
      <div className={styles.addressDetails}>
        <InputField label="Bairro*" name={"neighborhood"} placeholder="Bairro" register={register} registerField={"neighborhood"} errors={errors}/>
        <InputField label="Cidade*" name={"city"} placeholder="Cidade" register={register} registerField={"city"} errors={errors}/>
        <InputField label="Estado*" name={"state"} placeholder="Estado" register={register} registerField={"state"} errors={errors}/>
      </div>
    </div>
  );
}

export default AddressFields;