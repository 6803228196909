import React from 'react';
import styles from './eventDetails.module.css';
import { useNavigate } from 'react-router';
import { CgSoftwareDownload } from 'react-icons/cg';

const Footer = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/events");
  };
  return (
    <footer className={styles.footer}>
      <button className={styles.backButton} onClick={handleBack}>Voltar</button>
      <button className={styles.downloadButton}>
        <CgSoftwareDownload className={styles.buttonIcon} />
        Baixar Planilha Completa
      </button>
    </footer>
  );
};

export default Footer;