import { CgCalendar, CgClose, CgLogOut, CgMathPlus, CgMenu, CgUser } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router";
import styles from "./sideBar.module.css";
import common from "../../../shared/common";
import { getUserData } from "../../../helper/auth";
import roleType from "../../../shared/enum/roleType";
import { useState } from "react";

function SideBar({handleLogout}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  const isMobile = () => {
    return window.innerWidth <= 991
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (isMobile()) {
      setShowMenu(false);
    }
  };
  const userType = getUserData().role
  const showUsersTable = userType === roleType.admin

  const getPageActive = (page) => {
    return location.pathname === page ? styles.active : common.EmptyString;
  }

  return (
    <>
      <aside className={styles.sidebar}>
      <button
          className={`${styles.loggout}`}
          onClick={handleLogout}
        >
          <CgLogOut />
        </button>
        <img
          src="https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/iamticket-xEtLusgyWTtaKdCe6Jw0CyRacciA1a.png"
          alt="IamTicket"
          className={styles.sidebarImage}
        />
        <div className={styles.filterBar} />
        <button
          className={`${styles.pageButton} ${getPageActive('/events')} menu`}
          onClick={() => {
            navigate('/events');
          }}
        >
          <CgCalendar />
          Meus Eventos
        </button>
        {showUsersTable && <button
          className={`${styles.pageButton} ${getPageActive('/users')} menu`}
          onClick={() => {
            navigate('/users');
          }}
        >
          <CgUser />
          Meus Usuários
        </button>}
        <button className={styles.menuButton} onClick={toggleMenu}>
          {!showMenu ? <CgMenu /> : <CgClose />}
        </button>
      </aside>
      {showMenu && isMobile() && <div className={styles.menuMobile} >
        <button
          className={`${styles.pageButton} ${styles['mobile']} ${styles['active']}`}
          onClick={() => {
            navigate('/events');
          }}
        >
          <CgCalendar />
          Meus Eventos
        </button>
        {showUsersTable && <button
          className={`${styles.pageButton} ${styles['mobile']} ${styles['active']}`}
          onClick={() => {
            navigate('/users');
          }}
        >
          <CgUser />
          Meus Usuários
        </button>}
        <button
          className={`${styles.pageButton} ${styles['mobile']} ${styles['active']} ${styles['new']} `}
          onClick={() => {
            navigate('/new-event');
          }}
        >
          <CgMathPlus />
          Cadastrar Novo Evento
        </button>
      </div>}
    </>
  )
}

export default SideBar;