import React from "react";
import Header from "../componentes/Header";
import Signup from "../componentes/Signup";

const SignupPage = () => {
  return (
    <div className="body">
      <Header/>
      <Signup/>
    </div>
  );
};

export default SignupPage;
