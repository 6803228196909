import { useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import './signup.css'
import { IamTicketService } from "../../../integrations/iam-ticket-service";
import InputField from "../input/inputField";

const schema = yup
  .object({
    name: yup.string().required("Nome é obrigatório"),
    password: yup.string().required("Senha é obrigatória"),
    email: yup.string().email("Deve ser um email válido").required("Email é obrigatório"),
    businessId: yup.string().required("CNPJ é obrigatório"),
  })
  .required();

const Signup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const iamTicketService = new IamTicketService()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAdd = async (data) => {
    setIsLoading(true)
    const result = await iamTicketService.Signup(data);
    alert(result.message)
    setIsLoading(false)
    if (result.success) {
      navigate("/email-confirmation", { state: { email: data.email } })
    }
  };

  return (
    <section className="signup">
      <form className="signup__rectangle-2" onSubmit={handleSubmit(handleAdd)}>
        <div className="signup__rectangle"></div>

        <h1 className="signup__title">Crie uma conta para começarmos</h1>

        <InputField
          label=""
          placeholder="Nome"
          register={register}
          registerField={"name"}
          errors={errors}
        />
        <InputField
          label=""
          placeholder="CNPJ"
          register={register}
          registerField={"businessId"}
          errors={errors}
        />
        <InputField
          label=""
          placeholder="E-mail"
          register={register}
          registerField={"email"}
          errors={errors}
        />
        <InputField
          label=""
          placeholder="Senha"
          register={register}
          registerField={"password"}
          errors={errors}
          type="password"
        />

        <div className="signup__link">
          <button type="submit" className={isLoading? "signup__btn": "signup__btn__dark"}>
            Criar conta
          </button>
        </div>
      </form>
    </section>
  );
};

export default Signup;