import { useNavigate } from 'react-router';
import './loggin.css'
import { IamTicketService } from '../../../integrations/iam-ticket-service';
import { isValidToken, setUserToken } from '../../../helper/auth';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from '../input/inputField';

const schema = yup
  .object({
    password: yup.string().required("Senha é obrigatória"),
    email: yup.string().email("Deve ser um email válido").required("Email é obrigatório"),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = async (data) => {
    if (isLoading) return;
    setIsLoading(true);
    const iamTicketService = new IamTicketService();
    const { isAuth, token } = await iamTicketService.LogginUser(
      data.email,
      data.password
    );

    if (isAuth && isValidToken(token)) {
      setUserToken(token);
      navigate("/events");
      setIsLoading(false);
      return;
    }

    alert("Usuário ou Senha inválidos. Tente novamente!");
    setIsLoading(false);
  };

  return (
    <section className="login">
      <form className="login__rectangle-2" onSubmit={handleSubmit(handleLogin)}>
        <div className="login__rectangle"></div>

        <h1 class="login__title">Olá! Seja bem vindo de volta</h1>

        <p class="login__text">Faça o seu login agora</p>

        <InputField
          label=""
          placeholder="E-mail"
          register={register}
          registerField={"email"}
          errors={errors}
        />
        <InputField
          label=""
          placeholder="Senha"
          register={register}
          registerField={"password"}
          errors={errors}
          type="password"
        />

        <div className="login__link">
          <a class="login__forgot__password" href="">Esqueceu sua senha?</a>

          <button class={isLoading ? `login__btn` : `login__btn__dark`} type="submit">Continuar</button>

          <a class="login__create__account" href="/signup">Cadastrar uma nova conta</a>
        </div>
      </form>
    </section>
  )

}

export default Login