import React from "react";
import styles from "./eventCard.module.css";
import { getHoursAndMinutes, getLocaleDate } from "../../../helper/common";
import eventStatus from "../../../shared/enum/eventStatus";
import { CgCalendar, CgPin, CgTime } from "react-icons/cg";
import common from "../../../shared/common";
import { useNavigate } from "react-router";

function EventCard(event) {
    const navigate = useNavigate();
    const { name, status, address, startDate, endDate } = event
    const getEventStatusColor = () => {
        switch (status) {
            case eventStatus.finished:
                return "green";
            case eventStatus.cancelled:
                return "red";
            default:
                return "yellow";
        }
    };

    const mapEventStatus = {
        [eventStatus.finished]: "Finalizado",
        [eventStatus.cancelled]: "Cancelado",
        [eventStatus.ongoing]: "Em andamento",
        [eventStatus.scheduled]: "A realizar",
    }

    const getEventStatus = (status) => {
        return mapEventStatus[status];
    }

    const getIconColor = () => {
      return `${getEventStatusColor()}-icon`;
    }

    const getFullAddress = () => {
        if (!address) return common.Loading;
        return `${address.street}, ${address.number}, ${address.city} - ${address.state}`;
    };

    const getDateRange = () => {
        if (!startDate || !endDate) return common.Loading;
        return `${getLocaleDate(startDate)} até ${getLocaleDate(endDate)}`;
    };

    const getTimeRange = () => {
        if (!startDate || !endDate) return common.Loading;
        return `${getHoursAndMinutes(startDate)} - ${getHoursAndMinutes(
            endDate
        )}`;
    };

    const handleDetails = () => {
        if(!event) return
        navigate("/event-details", { state: { event }});
    }

    return (
        <article
            className={`${styles.eventCard} ${styles[getEventStatusColor()]}`}
        >
            <div className={styles.statusBar} />
            <div className={styles.cardContent}>
                <div className={styles.cardHeader}>
                    <h2 className={styles.eventTitle}>{name}</h2>
                    <span
                        className={`${styles.eventStatus} ${
                            styles[getEventStatusColor()]
                        }`}
                    >
                        {getEventStatus(status)}
                    </span>
                </div>
                <div className={styles.eventDetails}>
                    <p className={styles.eventDate}>
                        <CgCalendar className={`${styles.icon} ${styles[getIconColor()]}`} />
                        {getDateRange()}
                    </p>
                    <p className={styles.eventTime}>
                        <CgTime className={`${styles.icon} ${styles[getIconColor()]}`} />
                        {getTimeRange()}
                    </p>
                    <p className={styles.eventLocation}>
                        <CgPin className={`${styles.icon} ${styles[getIconColor()]}`} />
                        {getFullAddress()}
                    </p>
                </div>
                <button className={styles.editButton} onClick={handleDetails}>
                    Editar e/ou visualizar
                </button>
            </div>
        </article>
    );
}

export default EventCard;