import React, { useRef } from "react";
import styles from "./eventForm.module.css";
import InputField from "../../componentes/input/inputField";
import DateTimeField from "../../componentes/input/dateTimeField";
import AddressFields from "../../componentes/input/addressFields";
import FileUpload from "../../componentes/fileUpload/fileUpload";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { put } from "@vercel/blob";
import { config } from "../../../configs/env";
import { resolveDateAndTime } from "../../../helper/common";
import common from "../../../shared/common";
import { IamTicketService } from "../../../integrations/iam-ticket-service";
import { getToken } from "../../../helper/auth";

const schema = yup
  .object({
    eventName: yup.string().required("Nome do evento é obrigatório"),
    tickets: yup.string(),
    startDate: yup
      .string()
      .required("Data de início é obrigatória"),
    startTime: yup.string().required("Hora de início é obrigatória"),
    endDate: yup
      .string()
      .required("Data de término é obrigatória"),
    endTime: yup.string().required("Hora de término é obrigatória"),
    street: yup.string().required("Rua é obrigatória"),
    number: yup.string().required("Número é obrigatório"),
    zip: yup.string().required("CEP é obrigatório"),
    neighborhood: yup.string().required("Bairro é obrigatório"),
    city: yup.string().required("Cidade é obrigatória"),
    state: yup.string().required("Estado é obrigatório"),
    complement: yup.string(),
  })
  .required();

function EventForm({navigate, setEventId}) {
  const inputFileRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    const startDate = resolveDateAndTime(data.startDate, data.startTime);
    const endDate = resolveDateAndTime(data.endDate, data.endTime);
    const banner = await handleFileUpload();
    const newData = {
      name: data.eventName,
      tickets: Number(data.tickets),
      startDate,
      endDate,
      banner,
      address: {
        street: data.street,
        number: data.number ,
        city: data.city,
        state: data.state,
        zip: data.zip,
        neighborhood: data.neighborhood,
        complement: data.complement,
      }
    };
    
    const iamTicketService = new IamTicketService(getToken())
    const {eventId , success} = await iamTicketService.CreateEvent(newData);
    
    if(success) setEventId(eventId)
  };

  const handleFileUpload = async () => {
    try {
      if (!inputFileRef) return common.EmptyString;

      const file = inputFileRef.current.files[0];

      const newBlob = await put(file.name, file, {
        access: "public",
        token: config.vercelBlobReadWriteToken,
      });
      return newBlob.url;
    } catch (error) {
      console.log("Error while trying to upload banner", error)
      return common.EmptyString
    }
  };

  return (
    <form className={styles.eventForm} onSubmit={handleSubmit(onSubmit)}>
      <h1 className={styles.formTitle}>Novo evento</h1>
      <div className={styles.formUnderline} />

      <InputField
        label="Nome do evento*"
        placeholder="Nome"
        register={register}
        registerField={"eventName"}
        errors={errors}
      />

      <div className={styles.dateTimeWrapper}>
        <DateTimeField
          label="Data de início*"
          datePlaceholder="dd/mm/aaaa"
          timePlaceholder="00:00"
          nameDate={"startDate"}
          nameTime={"startTime"}
          register={register}
          errors={errors}
        />
        <DateTimeField
          label="Data de término*"
          datePlaceholder="dd/mm/aaaa"
          timePlaceholder="00:00"
          nameDate={"endDate"}
          nameTime={"endTime"}
          register={register}
          errors={errors}
        />
      </div>

      <AddressFields errors={errors} register={register} />

      <InputField
        label="Quantidade de tickets disponíveis"
        placeholder="Apenas número"
        type="number"
        register={register}
        registerField={"tickets"}
        errors={errors}
      />

      <div className={styles.descriptionField}>
        <label htmlFor="eventDescription" className={styles.fieldLabel}>
          Descrição do evento
        </label>
        <textarea
          id="eventDescription"
          className={styles.descriptionInput}
          placeholder="Descrição"
          name="eventDescription"
          {...register("eventDescription")}
        />
        <span className={styles.characterCount}>250 caracteres</span>
      </div>

      <FileUpload label="Banner" inputFileRef={inputFileRef} />

      <button type="submit" className={styles.submitButton}>
        Continuar
      </button>
    </form>
  );
}

export default EventForm;
