import { config } from "../configs/env";
import jwt from "jsonwebtoken";
import common from "../shared/common";

const TOKEN_KEY = "@app-Token";

export const isAuthenticated = () => {
  const token = getToken();

  return token !== null && isValidToken(token);
};

export const isValidToken = (token) => {
  try {
    return !!jwt.verify(token, config.secretKey);
  } catch (error) {
    console.log("error while trying to verify token: ", error);
    return false;
  }
};

export const getTokenData = () => {
  const token = getToken();
  return jwt.decode(token);
};

export const getUserData = () => {
  const userData = getTokenData();
  return {
    name: userData?.name ?? common.EmptyString,
    role: userData?.role ?? common.EmptyString,
  };
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setUserToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
