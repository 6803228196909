import React from 'react';
import styles from './dateTimeField.module.css';

function DateTimeField({ label, datePlaceholder, timePlaceholder, nameDate, nameTime, register, errors }) {
  const getDateTimeError = (dateError, timeError) => {
    if (dateError && timeError) {
      return `${dateError}; ${timeError}`;
    } else if (dateError) {
      return dateError;
    } else {
      return timeError;
    }
  }
  
  return (
    <div className={styles.dateTimeWrapper}>
      <label className={styles.dateTimeLabel}>{label}</label>
      <div className={styles.inputGroup}>
        <input type="date" placeholder={datePlaceholder} name={nameDate} className={styles.dateInput} { ...register(nameDate)}/>
        <input type="time" placeholder={timePlaceholder} name={nameTime} className={styles.timeInput} { ...register(nameTime)}/>
      </div>
      <p className={styles.errorMessages}>{getDateTimeError(errors[nameDate]?.message, errors[nameTime]?.message)}</p>
    </div>
  );
}

export default DateTimeField;