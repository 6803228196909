import React, { useRef } from "react";
import styles from "./eventForm.module.css";
import FileUpload from "../../componentes/fileUpload/fileUpload";
import { put } from "@vercel/blob";
import { config } from "../../../configs/env";
import common from "../../../shared/common";
import { IamTicketService } from "../../../integrations/iam-ticket-service";
import { getToken } from "../../../helper/auth";
import { CgSoftwareDownload } from "react-icons/cg";

function EventFormTickets({ navigate, eventId }) {
  const inputFileRef = useRef(null);
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    if(isLoading) return;
    setIsLoading(true);
    if(!inputFileRef.current.files[0]) {
      setErrors({file: "*Arquivo de usuários é obrigatório"});
      setIsLoading(false);
      return;
    }

    const target = await handleFileUpload();
    const iamTicketService = new IamTicketService(getToken());
    const { success } = await iamTicketService.UpdateEvent(eventId, { target});

    setIsLoading(false);
    if(success) navigate("/events");
  };

  const handleFileUpload = async () => {
    try {
      if (!inputFileRef.current) return common.EmptyString;

      const file = inputFileRef.current.files[0];

      const newBlob = await put(file.name, file, {
        access: "public",
        token: config.vercelBlobReadWriteToken,
      });
      return newBlob.url;
    } catch (error) {
      console.log("Error while trying to upload banner", error);
      return common.EmptyString;
    }
  };

  return (
    <form className={styles.eventForm} onSubmit={onSubmit}>
      <h1 className={styles.formTitle}>Novo evento</h1>
      <div className={styles.formUnderline} />

      <label className={styles.fieldLabel}>
        {
          "Para finalizar o cadastro do seu evento será necessário baixar a planilha abaixo e preenchê-la:"
        }
      </label>
      <a className={styles.dowloadExampleButton} href="https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/exampleIamTicket-3MfFIN6OpZvzZ3ZI2x4ObXzZRo5m7k.xlsx">
        <CgSoftwareDownload />
        Baixar Planilha Modelo
      </a>

      <label className={styles.fieldLabel}>
        {"Agora com a planilha preenchida corretamente suba-a aqui:"}
      </label>
      <FileUpload label="" inputFileRef={inputFileRef} />
      <p className={styles.errorMessages}>{errors?.file}</p>

      <button type="submit" className={isLoading ? styles.submitButtonInactive : styles.submitButton}>
        Finalizar
      </button>
    </form>
  );
}

export default EventFormTickets;
