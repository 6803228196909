export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getLocaleDate = (date) => {
  if (!date) return "";
  return new Date(date)?.toLocaleDateString("pt-br");
};

export const getHoursAndMinutes = (date) => {
  if (!date) return "";
  const dateObj = new Date(date);
  return dateObj.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
}

export const getFirstLetter = (text) => {
  if (!text) return "";
  return Array.from(text)[0];
}

export const resolveDateAndTime = (date, time) => {
  const finalDate = new Date(date)
  const times = time.split(":")
  finalDate.setHours(Number(times[0]), Number(times[1]))

  return finalDate.toISOString()
}


export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}