import React, { useEffect, useState } from "react";
import EventCard from "./eventCard";
import styles from "./eventPage.module.css";
import { IamTicketService } from "../../../integrations/iam-ticket-service";
import { useLocation, useNavigate } from "react-router";
import { getToken, logout } from "../../../helper/auth";
import commonTypes from "../../../shared/common";
import eventStatus from "../../../shared/enum/eventStatus";
import SideBar from "../../componentes/SideBar/sideBar";
import SearchHeader from "../../componentes/SearchHeader/searchHeader";

function EventsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const iamTicketService = new IamTicketService(getToken());
  const [events, setEvents] = useState([]);

  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  useEffect(() => {
    const fetchEvents = async () => {
      const data = await iamTicketService.getAllEvents(statusFilter, search, 1);
      setEvents(data.events);
      setMaxPage(Math.ceil(data.totalCount / 10));
      setPage(1);
    };
    fetchEvents();
  }, [statusFilter, search]);

  const handleLoadMore = async () => {
    const newPage = page + 1;
    const data = await iamTicketService.getAllEvents(
      statusFilter,
      search,
      newPage
    );
    setPage(newPage);
    setEvents((prevEvents) => [...prevEvents, ...data.events]);
    setMaxPage(Math.ceil(data.totalCount / 10));
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getEventStatusColor = (status) => {
    switch (status) {
      case eventStatus.finished:
        return "green";
      case eventStatus.cancelled:
        return "red";
      default:
        return "yellow";
    }
  };

  const getButtonFilterActive = (status) => {
    return statusFilter === status ? styles.active : commonTypes.EmptyString;
  };

  const getShouldShowSearch = () => {
    return location.pathname === "/events";
  };

  return (
    <main className={styles.eventsPage}>
      <SideBar handleLogout={handleLogout} />
      <section className={styles.mainContent}>
        <SearchHeader
          handleLogout={handleLogout}
          search={search}
          setSearch={setSearch}
          shouldShowSearch={getShouldShowSearch()}
        />
        <div className={styles.eventsContent}>
          <h1 className={styles.pageTitle}>Meus eventos</h1>
          <div className={styles.filterSection}>
            <div className={styles.filterBar} />
            <div className={styles.filterOptions}>
              <span>Filtrar eventos:</span>
              <button
                className={`${styles.filterButton} ${getButtonFilterActive(
                  commonTypes.EmptyString
                )}`}
                onClick={() => {
                  setStatusFilter(commonTypes.EmptyString);
                }}
              >
                Todos
              </button>
              <button
                className={`${styles.filterButton} ${
                  styles[getEventStatusColor(eventStatus.finished)]
                } ${getButtonFilterActive(eventStatus.finished)}`}
                onClick={() => {
                  setStatusFilter(eventStatus.finished);
                }}
              >
                Finalizado
              </button>
              <button
                className={`${styles.filterButton} ${
                  styles[getEventStatusColor(eventStatus.scheduled)]
                } ${getButtonFilterActive(eventStatus.scheduled)}`}
                onClick={() => {
                  setStatusFilter(eventStatus.scheduled);
                }}
              >
                A realizar
              </button>
              <button
                className={`${styles.filterButton} ${
                  styles[getEventStatusColor(eventStatus.ongoing)]
                } ${getButtonFilterActive(eventStatus.ongoing)}`}
                onClick={() => {
                  setStatusFilter(eventStatus.ongoing);
                }}
              >
                Em andamento
              </button>
              <button
                className={`${styles.filterButton} ${
                  styles[getEventStatusColor(eventStatus.cancelled)]
                } ${getButtonFilterActive(eventStatus.cancelled)}`}
                onClick={() => {
                  setStatusFilter(eventStatus.cancelled);
                }}
              >
                Cancelado
              </button>
            </div>
          </div>
          <div className={styles.eventsGrid}>
            {events.map((event) => (
              <EventCard key={event.id} {...event} />
            ))}
          </div>
          {page < maxPage && (
            <div className={styles.eventsLoadMore}>
              <button
                className={styles.loadMoreButton}
                onClick={handleLoadMore}
              >
                Carregar mais
              </button>
            </div>
          )}
        </div>
      </section>
    </main>
  );
}

export default EventsPage;
