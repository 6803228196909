import { CgLogOut, CgMathPlus, CgPassword, CgSearch } from "react-icons/cg";
import { getFirstLetter } from "../../../helper/common";
import styles from "./searchHeader.module.css";
import common from "../../../shared/common";
import { getUserData } from "../../../helper/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Popover } from "react-tiny-popover";

function SearchHeader({ search, setSearch, handleLogout, shouldShowSearch }) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: common.EmptyString,
    role: common.EmptyString,
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setUserData(getUserData());
    };
    fetchEvents();
  }, []);

  const getSearchEnabled = () => {
    return !shouldShowSearch ? "visuallyHidden" : common.EmptyString;
  };

  const handleNewEvent = () => {
    navigate("/new-event");
  };

  return (
    <header className={styles.header}>
      <button className={styles.newEventButton} onClick={handleNewEvent}>
        <CgMathPlus />
        <span>Cadastrar Novo Evento</span>
      </button>
      <div className={`${styles.searchForm} ${styles[getSearchEnabled()]}`}>
        <input
          type="search"
          id="searchInput"
          placeholder="Buscar"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchInput}
        />
        <CgSearch
          className={styles.searchIcon}
          onClick={() => setSearch(search)}
        />
      </div>
      <Popover
        isOpen={isPopoverOpen}
        positions={["bottom", "left"]}
        padding={10}
        reposition={false}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={({ position, nudgedLeft, nudgedTop }) => (
          <div className={styles.popover}>
            <button className={styles.popoverButton} onClick={()=>console.log("Change password")}>
              <>Alterar senha</> <CgPassword />
            </button>
            <p className={styles.popoverLine}></p>
            <button className={styles.popoverButton} onClick={handleLogout}>
              <>Sair</> <CgLogOut />
            </button>
          </div>
        )}
      >
        <div className={styles.userInfo} onClick={() => setIsPopoverOpen(true)}>
          <div>
            <p className={styles.userName}>{userData?.name}</p>
            <p className={styles.userRole}>{userData?.role}</p>
          </div>
          <div className={styles.userAvatar}>
            {getFirstLetter(userData.name)}
          </div>
        </div>
      </Popover>
    </header>
  );
}

export default SearchHeader;
