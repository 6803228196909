import React from "react";
import styles from "./eventRegistration.module.css";
import SideBar from "../../componentes/SideBar/sideBar";
import EventForm from "./eventForm";
import { logout } from "../../../helper/auth";
import { useNavigate } from "react-router";
import EventFormTickets from "./eventFormTickets";
import SearchHeader from "../../componentes/SearchHeader/searchHeader";

function EventRegistration() {
  const navigate = useNavigate();
  const [eventId, setEventId] = React.useState(null);
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div className={styles.eventRegistrationContainer}>
        <SideBar handleLogout={handleLogout} />
        <main className={styles.mainContent}>
          <SearchHeader shouldShowSearch={false} handleLogout={handleLogout} />
          {!eventId ? (
            <EventForm navigate={navigate} setEventId={setEventId} />
          ) : (
            <EventFormTickets navigate={navigate} eventId={eventId} />
          )}
        </main>
    </div>
  );
}

export default EventRegistration;
