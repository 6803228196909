import React, { useEffect, useState } from "react";
import styles from "./eventDetails.module.css";
import { CgChevronDoubleLeft, CgChevronDoubleRight, CgMore } from "react-icons/cg";
import { IamTicketService } from "../../../integrations/iam-ticket-service";
import { getToken } from "../../../helper/auth";
import common from "../../../shared/common";


const AttendeeList = ({ event, search }) => {
  const defaultLimit = 10;
  const [page, setPage] = useState(1);
  const [attendees, setAttendees] = useState([{email: common.Loading}]);
  const [totalPages, setTotalPages] = useState(0);
  const eventId = event?._id;
  const iamTicketService = new IamTicketService(getToken());

  useEffect(() => {
    async function fetchData() {
      const eventData = await iamTicketService.getAllTicketsByEvent(
        eventId,
        page,
        search ?? common.EmptyString
      );
      const att = eventData?.tickets?.length > 0? eventData?.tickets: [{email: common.NoResults}];
      setAttendees(att);
      const totalAttendees = eventData?.totalCount;
      setTotalPages(totalAttendees > defaultLimit? Math.ceil(totalAttendees / defaultLimit): 1);
    }
    fetchData();
  }, [search, page]);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };
  return (
    <section className={styles.attendeeList}>
      <table className={styles.attendeeTable}>
        <thead>
          <tr>
            <th>
              <input type="checkbox" aria-label="Select all attendees" />
            </th>
            <th>Email</th>
            <th>Ticket</th>
            <th>Acessos</th>
            <th>Status</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {attendees.map((attendee, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  aria-label={`Select ${attendee.email}`}
                />
              </td>
              <td>{attendee.email}</td>
              <td>{attendee.key}</td>
              <td>{attendee.accessQty}</td>
              <td>
                <span
                  className={`${styles.statusBadge} ${
                    attendee.active && styles.active
                  }`}
                >
                  {attendee.active ? "Ativo" : "Inativo"}
                </span>
              </td>
              <td>
                <button
                  className={styles.editButton}
                  aria-label={`Edit ${attendee.email}`}
                >
                  <CgMore />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <nav className={styles.pagination}>
        {page > 1 &&<button
          className={styles.paginationButton}
          onClick={handlePreviousPage}
        >
          <CgChevronDoubleLeft className={styles.paginationIcon} />
          Página anterior
        </button>}
        <div></div>
        {page < totalPages &&<button className={styles.paginationButton} onClick={handleNextPage}>
          Próxima página
          <CgChevronDoubleRight className={styles.paginationIcon} />
        </button>}
      </nav>
    </section>
  );
};

export default AttendeeList;
