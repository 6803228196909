export const config = {
    env: process.env.REACT_APP_NODE_ENV || 'development',
    port: process.env.REACT_APP_PORT || 3000,
    integrations: {
        iamTicketService: {
            url: process.env.REACT_APP_IAM_TICKET_SERVICE_URL,
            authenticationToken: process.env.REACT_APP_IAM_TICKET_SERVICE_AUTHENTICATION_TOKEN
        }
    },
    secretKey: process.env.REACT_APP_SECRET_KEY,
    vercelBlobReadWriteToken: process.env.REACT_APP_VERCEL_BLOB_READ_WRITE_TOKEN ?? process.env.VERCEL_BLOB_READ_WRITE_TOKEN
}