import React from 'react';
import styles from './eventDetails.module.css';
import { CgCalendar, CgPin, CgSearch, CgTime } from 'react-icons/cg';
import eventStatus from '../../../shared/enum/eventStatus';
import common from '../../../shared/common';
import { getHoursAndMinutes, getLocaleDate } from '../../../helper/common';

const EventInfo = ({ event, search, setSearch }) => {
  const { name, status, address, startDate, endDate } = event
  const getEventStatusColor = () => {
    switch (status) {
      case eventStatus.finished:
        return "green";
      case eventStatus.cancelled:
        return "red";
      default:
        return "yellow";
    }
  };

  const mapEventStatus = {
    [eventStatus.finished]: "Finalizado",
    [eventStatus.cancelled]: "Cancelado",
    [eventStatus.ongoing]: "Em andamento",
    [eventStatus.scheduled]: "A realizar",
  }

  const getEventStatus = () => {
    return mapEventStatus[status];
  }

  const getIconColor = () => {
    return `${getEventStatusColor()}-icon`;
  }

  const getFullAddress = () => {
    if (!address) return common.Loading;
    return `${address.street}, ${address.number}, ${address.city} - ${address.state}`;
  };

  const getDateRange = () => {
    if (!startDate || !endDate) return common.Loading;
    return `${getLocaleDate(startDate)} até ${getLocaleDate(endDate)}`;
  };

  const getTimeRange = () => {
    if (!startDate || !endDate) return common.Loading;
    return `${getHoursAndMinutes(startDate)} - ${getHoursAndMinutes(
      endDate
    )}`;
  };
  return (
    <section className={styles.eventInfo}>
      <h1 className={styles.eventTitle}>{name}</h1>
      <div className={styles.eventActions}>
        <div className={styles.filterBar} />
        <div className={styles.search}>
          <CgSearch
            className={styles.searchIcon}
            onClick={() => setSearch(search)}
          />
          <input
            type="search"
            id="searchInput"
            placeholder="Buscar por email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={styles.searchInput}
          />
        </div>
        <span className={`${styles.eventStatus} ${styles[getEventStatusColor()]}`}>{getEventStatus()}</span>
      </div>
      <div className={styles.eventDetails}>
        <p className={styles.eventDate}>
          <CgCalendar className={`${styles.icon} ${styles[getIconColor()]}`} />
          {getDateRange()}
        </p>
        <p className={styles.eventTime}>
          <CgTime className={`${styles.icon} ${styles[getIconColor()]}`} />
          {getTimeRange()}
        </p>
        <p className={styles.eventLocation}>
          <CgPin className={`${styles.icon} ${styles[getIconColor()]}`} />
          {getFullAddress()}
        </p>
        <p className={styles.editLink}>
          <span className={styles.underline}>Clique aqui</span> para editar informações do evento
        </p>
      </div>
    </section>
  );
};

export default EventInfo;