import React from "react";
import Header from "../componentes/Header";
import Login from "../componentes/Loggin";

const LoginPage = () => {
  return (
    <div className="body">
      <Header/>
      <Login/>
    </div>
  );
};

export default LoginPage;
