import React, { useState } from 'react';
import styles from './inputField.module.css';
import { CgEye, CgEyeAlt } from "react-icons/cg";

function InputField({ label, placeholder, type = 'text', name, register, registerField = null, errors }) {
  const [eye, setEye] = useState(false)
  const souldShowEye = () => {
    return type === "password"
  }

  return (
    <div className={styles.inputWrapper}>
      <label className={styles.inputLabel}>{label}</label>
      <div className={styles.inputFieldWrap}>
        <input type={ !eye ? type : "text" } placeholder={placeholder} className={styles.inputField} name={name} {...registerField ? register(registerField) : null} />
        { souldShowEye() && (!eye ? <CgEye className={styles.inputEye} onClick={()=> setEye(!eye)}/> : <CgEyeAlt className={styles.inputEye} onClick={()=> setEye(!eye)}/> )}
      </div>
      <p className={styles.errorMessages}>{errors[registerField]?.message}</p>
    </div>
  );
}

export default InputField;