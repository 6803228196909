import React from 'react';
import styles from './fileUpload.module.css';
import { useState } from 'react';
import { CgArrowUpO } from "react-icons/cg";

function FileUpload({ label, inputFileRef }) {
  const [fileName, setFileName] = useState('Nenhum arquivo anexado');

  const setFileConfigs = () => {
    setFileName(inputFileRef.current.files[0]?.name ?? 'Nenhum arquivo anexado');
  }

  return (
    <div className={styles.fileUploadWrapper}>
      <label className={styles.fileUploadLabel}>{label}</label>
      <input id="filePicker" name="file" ref={inputFileRef} type="file" onChange={setFileConfigs} style={{visibility:"hidden"}} />
      <label htmlFor="filePicker" className={styles.dropZone}>
        <CgArrowUpO className={styles.uploadIcon} />
        <p className={styles.dropInstructions}>
          Clique aqui para iniciar o envio
        </p>
        <p className={styles.dropInstructions}>
          {`Arquivo anexado: ${fileName}`}
        </p>
        <label htmlFor="filePicker" className={styles.fileSelectButton}>
          Selecione um arquivo para carregar
        </label>
      </label>
    </div>
  );
}

export default FileUpload;